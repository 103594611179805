import * as _ from 'lodash';
import {engravingColors, engravingKinds} from '../constants';

 
/**
 * Génère l'image correspondant à une gravure.
 * Le passage par un canvas au lieu de bête texte stylé en css est la possibilité
 * de redimensionner la gravure à une largeur spécifique.
 * 
 * 
 */
function  getOrgFonts (user,available=false){
  let engravingFonts = _.get(user,'identity.organization.fonts');
  if (available && engravingFonts) {
    const availableFonts = {}
    for (let family in engravingFonts) {
      if(engravingFonts[family].available) {availableFonts[family] = engravingFonts[family]}
    }
    return availableFonts
  }else {
    return engravingFonts
  }
  
}
function getFontsUrl(user){
  let fontUrl = _.get(user,'identity.organization.fontsUrl');
  return fontUrl
}
export function generateEngravingImage(engraving, size = 200, user) {

  const lines = engraving.text.split('\n');
  const lineHeight = (engraving.font.size + engraving.font.lineHeight);

  const ctx = makeCanvasContext(size, size);

  const scale = getScaleToFitCanvas(lines, lineHeight, ctx, size, engraving,user);

  setTextStyle(user,ctx, engraving, scale);

  writeLinesOnCanvas(ctx, lines, engraving, lineHeight, scale, size);

  return ctx.canvas.toDataURL("image/png");
}

function makeCanvasContext(width, height) {
  var canvas = document.createElement('canvas');
  canvas.setAttribute('width', width);
  canvas.setAttribute('height', height);
  return canvas.getContext("2d");
}

function setTextStyle(user,ctx, engraving, scale = 1) {
  const engravingFonts = _.get(user,'identity.organization.fonts');
  const fontFamily = engravingFonts[engraving.font.family].family;
  ctx.font = Math.round(engraving.font.size * scale) + 'px ' + fontFamily;
  ctx.textAlign = engraving.font.align;
  ctx.textBaseline = 'middle';
  ctx.fillStyle = engraving.font.color;
  ctx.strokeStyle = 'rgba(0, 0, 0, 0.75)';
  ctx.lineWidth = 0.75;
}

function getScaleToFitCanvas(lines, lineHeight, ctx, size, engraving,user) {
  // On fixe le style de texte pour pouvoir mesurer la largeur qu'il occuperait
  // à une échelle 1:1
  setTextStyle(user, ctx, engraving);
  // On calcule la zone occupée par le texte à l'échelle 1:1
  const trueSize = getTextBlockSize(lines, lineHeight, ctx);
  // On en déduit l'échelle à appliquer pour que le texte passe dans le canvas
  return Math.min(size / trueSize.width, size / trueSize.height) * 0.9;
}

function getTextBlockSize(lines, lineHeight, ctx) {
  return {
    width: _.max(lines.map((text)=>ctx.measureText(text).width)),
    height: lineHeight * lines.length
  }
}

function writeLinesOnCanvas(ctx, lines, engraving, lineHeight, scale, size) {
  const relativeLineHeight = lineHeight * scale / size
  // Le canvas ne gère pas le texte multiligne nativement
  lines.forEach((lineText, i)=> {
    ctx.fillText(lineText,
      getLineX(engraving.font.align) * size,
      getLineY(i, lines.length, relativeLineHeight) * size
    );
    ctx.strokeText(lineText,
      getLineX(engraving.font.align) * size,
      getLineY(i, lines.length, relativeLineHeight) * size
    );
  })
}

function getLineX(align) {
  // Alignement de la ligne horizontalement, avec 5% de marge
  return ({
    left: 0.05,
    center: 0.5,
    right: 0.95
  })[align];
}
function getLineY(i, totalLines, lineHeight) {
  // Centrage du bloc verticalement
  const yStart = (1 - lineHeight * totalLines) / 2;
  // Décalage de la n-ème ligne vers le bas
  const yOffset = lineHeight * (0.5 + i);
  return yStart + yOffset;
}

const filterEngravingTypesWithPossibleFonts = (user,engravingTypes)=>{
  const availableFonts = getOrgFonts(user,true);
  const finalTypes = engravingTypes.filter(item=>availableFonts[item.fontFamily.trim()]);
  return finalTypes
}

export const getEngravingAvailableInfo = (user,engravingsList,kind=null,color=null,family=null,excludedTypes=null) => {
  // Il only available are requested
  const availableEngravings =  filterEngravingTypesWithPossibleFonts(user,engravingsList);
  const engravings = excludedTypes && excludedTypes.length>0 ?
                    availableEngravings.filter(engraving=>!excludedTypes.some(excluded=>excluded.value === engraving.type)) :
                    availableEngravings;
  // return the possibilities depending on prices available (options)
  const possibleTypes = getPossibleTypes(engravings);
  const chosenType = !kind ? possibleTypes[0]:kind; // if no kind defined take first
  const colors = getPossibleColors(engravings,chosenType);
  let selectedColor = colors.find(item=> color && item.id.includes(color.slice(0,-2)));
  selectedColor = selectedColor ? selectedColor : colors.length >0? colors[0]:null;
  const fonts = getPossibleFonts(engravings,chosenType,selectedColor,user);
  let selectedFont = fonts.find(item=>item.id === family);
  selectedFont = selectedFont ? selectedFont : fonts.length >0 ?fonts[0]:null;  
  return ({types:possibleTypes,selectedType:chosenType,colors:colors,
          selectedColor:selectedColor,fonts:fonts,selectedFont:selectedFont});
}

const getPossibleTypes = engravings =>{
  let possibleTypes = [];
  let types = engravings.map(a => a.type);
  Object.keys(engravingKinds).forEach((prop)=>{    types.forEach(type=>{
      if (type.indexOf("Lettres "+prop)!==-1 && !possibleTypes.includes(prop)){
        possibleTypes.push (prop);
      }
    })
  })
  return possibleTypes;
}
const getPossibleColors = (engravings,kind)=>{
  let possibleColors = [];
  let types = engravings.map(a => a.type);
  Object.keys(engravingColors).forEach((prop)=>{
      addColors(types,engravingColors,prop,kind,possibleColors);
  })
  // on enlève les doublons
  possibleColors = possibleColors.filter((color,index,self )=>
  index === self.findIndex((t) => (t.id === color.id)))
    return possibleColors;
}
const addColors=(types,engravingColors,prop,kind,possibleColors)=>{
  types.forEach(type=>{
     // Pour gérer les argentés en fin de texte
    if (type.indexOf("Lettres "+kind)!==-1 && type.indexOf(engravingColors[prop])!==-1 ){
      possibleColors.push({id:prop,label:engravingColors[prop]});
    }
  })
}
const getPossibleFonts = (engravings,kind,color,user)=>{
  let possibleFonts = [];
  if (color){
    const engravingFonts = _.get(user,'identity.organization.fonts');
   
    Object.keys(engravingFonts).forEach((prop)=>{
      engravings.forEach(engraving=>{
        // Pour gérer les argentés en fin de texte 
        if (engraving.type.indexOf("Lettres "+kind)!==-1 &&  engraving.type.indexOf(color.label )!==-1   && engraving.fontFamily.trim() === prop ){
          possibleFonts.push({id:prop,data:engravingFonts[prop]});
        }
      })
    })
  }
    return possibleFonts;
}

export const getFontdefaultSize = (family,user)=>{
  const engravingFonts = getOrgFonts(user);
  if (engravingFonts && engravingFonts[family]){ return engravingFonts[family].defaultSize }
  return 30
}
export const getFontInfo = (family,user)=>{
  const engravingFonts = getOrgFonts(user);
  if (engravingFonts && engravingFonts[family]){ return engravingFonts[family] }
  return null
}

export const loadFont = (name,font,user)=>{
  let newStyle = document.createElement('style');
  let fontUrl = `${getFontsUrl(user)}/${font}`;
  newStyle.appendChild(document.createTextNode(`
    @font-face {font-family: '${name}';
    src:  url('${fontUrl}.woff') format('woff'),
        url('${fontUrl}.woff2') format('woff2')}
  `));
  document.head.appendChild(newStyle);
}
// get opacity
export const engravingOpacity = (user)=>{
  const opacity =  _.get(user,'identity.organization.engravingOpacity');
  return parseFloat(opacity)
}
// initially we load all the available fonts for the organization
export const loadFonts = (user)=>{
  const fonts = getOrgFonts(user,true);
  for (let item in fonts) { loadFont(fonts[item].family,fonts[item].cssFont,user)}
}

export const checkLoadedFonts = (user,configuration) => {
   // Check in case a used font is not available anymore but must de displayed
  const engravings = _.get(configuration,'configuration.engravings');
  if (engravings){
    const engravingFonts = engravings.map(eng=>eng.font.family);
    const uniqFamilyFonts = [...new Set(engravingFonts)];
    const loadedFonts = getOrgFonts(user,true);
    const allFonts = getOrgFonts(user);
    uniqFamilyFonts.forEach(font=>{
      if (loadedFonts && !loadedFonts[font]){
        if (allFonts[font]){
          loadFont(allFonts[font].family,allFonts[font].cssFont,user);
        }
      }
    })
  }

}
