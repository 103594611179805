import * as types from './actionTypes';
import {getExternal,putExternal,deleteExternal,patchExternal} from '../services/api.service';
import {displayStandardConfigImage} from '../services/image.fct.service';
import {assignDeep} from '../services/assignDeep.service';
import * as _ from "lodash";

// Récupération du devis et stockage dans redux avec l'image principale
export const initConfigWithQuote =  (configId)=>{
    
    
  return (dispatch) => {
    dispatch({type: types.CONFIGURATION_INIT_START});
    getExternal(`quote_variants/${configId}`).then(theQuote=>{
      theQuote.configuration = _.cloneDeep(theQuote.quote_variant);
      if(!theQuote.configuration.additional){
        theQuote.configuration.additional={lines:[]};
      }
      delete theQuote.quote_variant;
      dispatch({
        type: types.CONFIGURATION_GET_SUCCESS,
        payload: {
          configuration:theQuote,
          isoView:displayStandardConfigImage(theQuote.configuration)
        }})
  })
}
}

// Mise à jour de la configuration
export const updateConfigWithQuote= (dispatch,configuration,action,user,options,prevCurrent,current)=>{
  quoteActions(configuration,action.payload,action.type,options,user,prevCurrent.configuration).then(result=>{
    if (result ==="no_action"){ // Cas où il n'y a pas d'action particulière
      return
    }
    if (result){
      if (result.quote_variant){
      // Remise à jour de la config au complet
      dispatch({ type: types.CONFIG_UPDATE_ALL_CONFIG, payload: result.quote_variant})
      current.configuration = result.quote_variant
      }else if (result.id){ //Mise à jour de l'élément
        const domain = getDomainApi(action.type, action.payload);
        if (domain){
          let updateData= {};
          updateData[domain] = [result];
          dispatch({ type: types.CONFIG_UPDATE_FABRIC_ELEMENTS, payload: updateData})
        }
      }
    if (current) {
      dispatch({ type: types.CONFIG_UPDATE_ISO_VIEW, payload:displayStandardConfigImage(current.configuration) });
    }
    dispatch({
      type: types.CONFIG_UPDATE_SUCCESS,
      payload: { 
        "configuration": current.configuration,
        "options": options,
        "patterns": current.patterns,
        "accessories": current.accessories,
        "headstones": current.headstones,
        "prestgrav": current.prestgrav
      }
    });
    
  }else{
    //Quelque chose s'est mal passé on revient en arrière
    dispatch({type:types.CONFIGURATION_REVERT_CONFIG,payload:prevCurrent})
  }
  })
}

const quoteActions = (configuration,payload,action,options,user,prevConfig)=>{
  const domain = getDomainApi(action, payload);
  // if (domain && ["accessories","patterns","engravings","granite"].includes(domain)){
    if (domain){
    const data = getDataToSend(action,payload,options,user,domain,configuration,prevConfig);
    
    if (data){
      const typeApi = getTypeApi(action,domain);
      const elementId = getElementId(payload,typeApi,data,action);
      
      if (domain  && configuration.reference && elementId){
        switch(typeApi){
          case "PUT":
            return putExternal(`quote_variants/${configuration.reference}/${domain}/${elementId}`,data);
          case "PATCH":
            return patchExternal(`quote_variants/${configuration.reference}`,data);
          case "DELETE":
            return deleteExternal(`quote_variants/${configuration.reference}/${domain}/${elementId}`);
          default:
            return Promise.resolve("no_action")
        }
      }
    }
  }
  return Promise.resolve("no_action")
}


const getElementId = (payload,typeApi,data,action)=>{
  //Pas besoin d'éléments pour le patch
  if (typeApi === "PATCH") return true;

  if (action === types.CONFIG_REMOVE_ITEM){
    return data
  }
  if (typeApi === "DELETE"){
    return payload[0];
  }
  return data.id
}

const getDataToSend = (action,payload,options,user,domain,configuration,prevConfig) =>{
  switch (action) {

    case types.CONFIG_UPDATE_GRANITE_GLOBAL:
        return ({granite_id:payload});
    case types.CONFIG_UPDATE_AT_PATH:
      let tmpUpdate = {};
      const retUpdate = assignDeep(tmpUpdate, payload.key , payload.value);
      return(retUpdate)

    case types.CONFIG_UPDATE_FLOWER_DISPLAY:
      return ({hideFlowers:payload});

    case types.CONFIG_UPDATE_PATTERN_REMOVE:
    case types.CONFIG_UPDATE_ENGRAVING_REMOVE:
    case types.CONFIG_UPDATE_ACCESSORY_REMOVE:
      if (payload && payload.length){
        return ({id:payload[0]})
      }
      return null

    case types.CONFIG_UPDATE_FABRIC_ELEMENTS:
      return manageUpdateElements(payload)

    case types.CONFIG_UPDATE_ACCESSORY_ADD:
    case types.CONFIG_UPDATE_PATTERN_ADD:
    // case types.CONFIG_UPDATE_ENGRAVING_ADD:
    case types.CONFIG_UPDATE_PATTERN_DUPLICATE:
    case types.CONFIG_UPDATE_ENGRAVING_DUPLICATE:
    case types.CONFIG_UPDATE_ACCESSORY_DUPLICATE:
      return getLastElement(configuration,domain)

    case types.CONFIG_REMOVE_ITEM:
      if (domain === "additional.lines") {
        
        return getItemInfo(configuration,domain,payload)
      }
      return getIndexElement(prevConfig,payload)

    case types.CONFIG_UPDATE_ITEM :
      return getItemInfo(configuration,domain,payload)

    default: // sur les ajouts on ne fait rien
      return null
  }
}

const getIndexElement = (config,payload)=>{
if (config[payload.key] && config[payload.key][payload.index]){
  return config[payload.key][payload.index].id
}
return null
}

const getLastElement = (configuration,domain) => {
  if (configuration && configuration[domain] && configuration[domain].length){
    //On renvoit le dernier élement qui vient juste d'être inséré dans Redux
    return configuration[domain][configuration[domain].length - 1]
  }
  return null
}

const getItemInfo = (configuration,domain,payload) =>{
  if (["engravings","patterns","accessories"].includes(domain)){
    const members = payload.key.split('.');
    //On récupère l' élément entier à l'index indiqué
    const indexElement = members[1];
    return configuration[domain][indexElement]
  } else if (domain === "additional" || domain === "additional.lines") {
    // On renvoie toutes les lignes à chaque fois
    return {additional: {lines: configuration["additional"]["lines"]}}
  } else if (payload.key && payload.key.indexOf("price.custom")!== -1){
    //sinon en cas de prix custom on remonte le prix de l'élément qui va bien
    let data= {};
    data[domain]={custom:payload.value};
    return data
  }
  return null
}

const manageUpdateElements = (payload,action=false) =>{
  // TODO On se limite à 1 pour l'instant car impossible de changer plusieurs éléments dans l'IHM
  if (payload.accessories && payload.accessories.length >0){
    return action ? "accessories": payload.accessories[0]
  }else if (payload.engravings && payload.engravings.length >0){
    return action ? "engravings" : payload.engravings[0]
  }else if (payload.patterns && payload.patterns.length >0){
    return action ? "patterns" : payload.patterns[0]
  }
  else return null
}

const isPatch = (domain)=>["monument","frame","veneer","granite","additional","additional.lines","flowers","familyInfos"].includes(domain)

const getTypeApi = (action,domain)=>{
  const type = isPatch(domain) ? "PATCH" : action.indexOf("REMOVE") !== -1 ? "DELETE":"PUT";
  return type;
}
const getDomainApi = (action,payload)=>{
  const domain = action.indexOf("FABRIC") !== -1 ? manageUpdateElements(payload,true) :
                 action.indexOf("ACCESSORY") !== -1 ? "accessories":
                 action.indexOf("ENGRAVING") !== -1 ? "engravings":
                 action.indexOf("FLOWER") !== -1 ? "flowers":
                 action.indexOf("PATTERN") !== -1 ? "patterns":
                 action.indexOf("GRANITE") !== -1 ? "granite":
                 action === types.CONFIG_UPDATE_AT_PATH ?  "familyInfos":
                 action === types.CONFIG_REMOVE_ITEM ?  payload.key:
                 action === types.CONFIG_UPDATE_ITEM ?  getUpdateItemDomain(payload) : null;
  return domain;
}
const getUpdateItemDomain = (payload)=>{
  if(payload.key.indexOf("additional.lines.") !== -1){
    return "additional"
  }else if (payload.key.indexOf("price.custom") !== -1){
    const members = payload.key.split('.');
    return members[0]
  }
}
const checkDomainUpdatePath = (payload) =>{
  if(payload.key.indexOf("family.payment.advance") !== -1){
    //Pour l'instant on ne gère que l'avance de paiement
    return "familyInfos"
  }
  return null
}


