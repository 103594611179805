import React, {Component} from 'react';
import cn from 'classnames';
import {getStore} from '../../../store';
import {CONFIG_UPDATE_SUCCESS, CONFIG_UPDATE_FAILURE, CONFIGURATION_GET_SUCCESS, CONFIGURATION_FAMILY_INFO_UPDATE_SUCCESS} from '../../../actions/actionTypes';
import * as _ from 'lodash';
import {connect} from 'react-redux';
import {getReactAppExtranet,isFamilySpaceOn} from '../../../services/utils.service';
import {LeftOvalAction} from '../../../components/LeftOvalAction';
import { Modal} from "../../../components/Modal";
import {ScreateSpace} from "../../family_space/create_space/ScreateSpace";
import checkIcon from '../../../images/space/check_orange.svg';
import closeIcon from '../../../images/space/close_darkblue.svg';
import history from "../../../history";
import { gtmFamily } from '../../../services/gtmFamily';
import { TranslationContext } from "../../../context/TranslationContext";

class NotifUpdateComponent extends Component {
  static contextType = TranslationContext;
  storeListener;
  state = {
    show: false,
    openCreate: false,
    isFamilyInfo: false
  };

  componentWillMount() {
    this.storeListener = getStore().subscribe(this.onStateChange);
  }
  componentDidMount() {

  }
  componentWillUnmount() {
    this.storeListener && this.storeListener();
    this.storeListener = null;
  }

  hideNotification = () => _.delay(() => {
    this.setState({show: false, isFamilyInfo: false});
  }, 3000)

  showSuccess = _.throttle(() => {
    this.setState({
      show: true,
      error: false,
    });
    this.hideNotification();
  }, 5000, {leading: true, trailing: true})

  showError = () => {
    this.setState({
      show: true,
      error: true,
    });
    this.hideNotification();
  }

  onStateChange = () => {
    switch (getStore().getState().lastAction) {
      case CONFIGURATION_GET_SUCCESS:
        const family =  this.props.configuration && this.props.configuration.configType === "family" ? true: false;
        if (family){
          return this.showSuccess();
        }else return "";

      case CONFIGURATION_FAMILY_INFO_UPDATE_SUCCESS:
        this.setState({ isFamilyInfo: true });
        return this.showSuccess();

      case CONFIG_UPDATE_SUCCESS:
        return this.showSuccess();
      case CONFIG_UPDATE_FAILURE:
        return this.showError();
        default:
          return "";
    }
  };

  clientDisplay = (redirectionPath)=> {
    const t = this.context;
    const svgSave = `
      <svg width="44" height="45" viewBox="0 0 44 45" fill="var(--secondary1)" xmlns="http://www.w3.org/2000/svg">
        <rect y="0.5" width="44" height="44" rx="5" fill=""/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M29.4958 17.0039C29.7691 17.2772 29.7691 17.7204 29.4958 17.9938L19.4958 27.9938C19.2224 28.2672 18.7792 28.2672 18.5058 27.9938L14.5058 23.9938C14.2324 23.7204 14.2324 23.2772 14.5058 23.0039C14.7792 22.7305 15.2224 22.7305 15.4958 23.0039L19.0008 26.5089L28.5058 17.0039C28.7792 16.7305 29.2224 16.7305 29.4958 17.0039Z" fill="white"/>
      </svg>
    `;

    return (
      <a className={cn('UpdateNotification', {show: this.state.show, error: this.state.error})}
          href={getReactAppExtranet("/" + redirectionPath + "?search=" + this.props.configuration.reference)}
          target="_blank" rel="noopener noreferrer">
        
        <div className='notif-content'>
          {this.state.isFamilyInfo ? (
            <h5>{this.state.error 
              ? 'Échec de l\'enregistrement' 
              : `Informations projet/famille enregistrées`}
            </h5>
          ) : (
            <>
              <h5>{this.state.error ? 'Échec de l\'enregistrement' : t('config3d_finalize_footer_button_save_title')}</h5>
              <p>{this.state.error ? 'Votre configuration n\'est pas sauvegardée' : t('config3d_finalize_footer_button_save_subtitle')}</p>
            </>
          )}
        </div>

        <div className='notif-icon'>
          {this.state.error ? (<i className="icon material-icons">&#xE2C1;</i>) : (
            <span className='icon' dangerouslySetInnerHTML={{ __html: svgSave }} />
          )}
        </div>
      </a>
    )
  } 


familyDisplay = ()=> {
  const {openCreate} = this.state;
  const connected = this.props.familySpace && this.props.familySpace.user;
  const accessText = connected ? "Voir dans mon Espace famille": "Créer votre espace famille";
  const {mainColor, fontColor} = this.props.adminConfig.config
  const svgCheckIcon = `<svg xmlns="http://www.w3.org/2000/svg" width="23" height="23" viewBox="0 0 23 23"><defs><style>.a{fill:#fff;}.b{fill:${mainColor} !important;}</style></defs><g transform="translate(-0.019 0.324)"><circle class="a" cx="11.5" cy="11.5" r="11.5" transform="translate(0.019 -0.324)" style="fill:none;"/><path class="b" d="M94.056,162.988l-5.623-5.623,2.047-2.047,3.576,3.576,7.093-7.093,2.047,2.047Z" transform="translate(-84.481 -145.226)"/></g></svg>`                    

  const action = () =>  {
    // GTM
    gtmFamily().ctaOfConfiguration({
      name: "créer votre espace famille",
      category: "espace famille",
      subcategory: "",
      type: "cta",
      from: "configuration enregistrée"
    }, { template: "configurateur", subtemplate: "" }, {}, "partner", "product", "user", "page")
    // end GTM
    if(connected) {
      history.push(`/famille/${this.props.clientId}/familyspace`)
    } else {
      this.setState({openCreate:true,show:false})
    }
  }; 
                    
 return ( 
  <div className={cn('UpdateNotification', {show: this.state.show, error: this.state.error})}>
    {!this.state.error && 
      <div className ="FamilyUpdate">
        <img className= "closeIcon" src={closeIcon} onClick={()=>this.setState({show:false})} alt="Fermeture"/>
        <div className="title">
          {/* <img src={checkIcon} alt="Configuration enregistrée"/> */}
          <div style={{display: "inline", marginRight: "2px"}} dangerouslySetInnerHTML={{ __html: svgCheckIcon }} />
          Configuration enregistrée
        </div>
        <LeftOvalAction colorConfig={{mainColor, fontColor}} text={accessText}  addClass="BlueA" icon="family_darkblue" action={()=> action()}/>
      </div>}
      <Modal className="FamilyCreateSpaceModal" isOpen={openCreate} onRequestClose={()=>this.setState({openCreate:false})}>
          <ScreateSpace origin="familyConfig" close={()=>this.setState({openCreate:false})}   />
      </Modal>
  </div>)
}

  render() {
    const family =  this.props.configuration && this.props.configuration.configType === "family" ? true: false; 
    const redirectionPath = this.props.configuration && !this.props.configuration.quoteId ? 'pro-space/configurations' : 'pro-space/quotes';  
    return (
    <div>
      {family && isFamilySpaceOn(this.props.user) && this.familyDisplay()}
      {!family && this.clientDisplay()}
    </div>)
  }
}

export const NotifUpdate = connect(state=>({
  configuration: (state.configurator.current && state.configurator.current.configuration) || {},
  familySpace: state.familyService,
  user:state.user,
  adminConfig: state.adminConfig
}))(NotifUpdateComponent)
