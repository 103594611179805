import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {HoverInfo} from "./HoverInfo";
import { getDesignTypeConfiguration } from '../services/utils.service';
import FLoader from './FLoader';
import defaultImage from './../../src/images/defaultImage.svg';
export const lazyImageSrcAttr = 'data-lazy-src';
export const lazyImageClassName = 'lazy-loader-image';


/**
 * detect IE
 * returns version of IE or false, if browser is not Internet Explorer
 *
 * https://codepen.io/gapcode/pen/vEJNZN
 */
function detectIE() {
  var ua = window.navigator.userAgent;
  return (ua.indexOf('MSIE ') || ua.indexOf('Trident/') || ua.indexOf('Edge/')) > 0;
}


/**
 * Encapsule une image et affiche un spinner durant le chargement.
 * Par défaut, le src de l'image n'est pas renseigné. Il le sera par le composant ListView.
 *
 * Si besoin d'un chargement immédiat, il faut mettre la props 'lazy' à false.
 * Dans ce cas, le composant sert uniquement à afficher un spinner le temps du chargement de l'image.
 */
export class LazyImage extends Component {
  static propTypes = {
    lazy: PropTypes.bool,
    onLoad: PropTypes.func,
    onError: PropTypes.func,
    noCdn: PropTypes.bool
  };

  state = {
    loaded: false,
    hover:false,
    imageLoadError:false
  };

  onImageLoaded = (event) => {
    this.setState({ loaded: true, imageLoadError:false });
    if (this.props.onLoad) this.props.onLoad(event);

    if (event.target && detectIE()) {
      event.target.style.zoom = getComputedStyle(event.target).zoom || 1; // Force refresh on IE browsers
    }
  };

  onImageError = (event) => {
    this.setState({ loaded: true, imageLoadError:true });
    if (this.props.onError) this.props.onError(event);
  };

  componentWillReceiveProps = (newProps) => {
    if (newProps.src !== this.props.src) {
      this.setState({ loaded: false });
    }
  };

  render() {
    const { imageLoadError } = this.state;
    let {src, className, classNameImg, lazy = true, lowResImage,hoverInfo, ...others} = this.props;
    src = (src || '').replace('undefined', ''); // Bug IE < 11
    lowResImage = (lowResImage || '').replace('undefined', ''); // Bug IE < 11
    const srcHash = src.split("").reduce((a,b) => a + b.charCodeAt(0), 0);
    const designType = getDesignTypeConfiguration();
    if (process.env.APP_ENV === 'production' && !this.props.noCdn){
        // TODO: consider dropping this pseudo cdn
        src = src.replace(/(https?):\/\/(?:api\.)?([^/]+\/?)/i, '$1://cdn' + Math.floor(srcHash % 10) + '.$2');
    }
    const srcAttr = lazy ? {[lazyImageSrcAttr]: src} : {src};
    let LowerResImage = <img decoding="async" src={lowResImage} className="LowResolution" alt="basse résolution"/>;
    let TheImage = <img decoding="async" className={cn(lazyImageClassName, {loading: !this.state.loaded}, classNameImg)} {...srcAttr} {...others}
                        onLoad={this.onImageLoaded} onError={this.onImageError} alt="contenu" style={this.props.frame?{transform: "scale(1) translate(0.2%, 1.5%)"}:{}}/>;
    return (
      <>
        { !this.props.hoverInfo && <>
          {!this.state.loaded && this.props.fromFamily && <FLoader size="S" fspCatalogGranit="fspCatalogGranit" />}
          <figure className={cn(designType === "B" ? 'LazyImageB' : 'LazyImage', className, {loading: !this.state.loaded,hasLowResolution: lowResImage})}>
            {lowResImage && LowerResImage}
            {!imageLoadError ? TheImage : <img src={defaultImage} alt="Image par défaut" />}
          </figure>
        </>
        }
        { this.props.hoverInfo &&    <figure
        className={cn(designType === "B" ? 'LazyImageB' : 'LazyImage', className, {loading: !this.state.loaded,hasLowResolution: lowResImage,hover:this.state.hover})}
        onMouseEnter={()=>this.setState({hover:true})} onMouseLeave={()=>this.setState({hover:false})}>
        { this.state.hover && <HoverInfo hoverInfo={this.props.hoverInfo}  ></HoverInfo>}
          {lowResImage && LowerResImage}
          {!imageLoadError ? TheImage : <img src={defaultImage} alt="Image par défaut" />}
        </figure>}
      </>
    );
  }
}
