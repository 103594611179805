import React, { Component } from "react";
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from "../../actions/updateConfigurationActions";
import * as configurationActions from "../../actions/configurationActions";
import * as uiActions from "../../actions/uiActions";
import { AccessoryCard } from "../configurator/accessory/AccessoryCard";
import * as _ from "lodash";
import { Button } from 'reactstrap';
import {OvalAction} from "../../components/OvalAction";
import {ScrollListView} from '../../components/ScrollListView';
import {isSmartphone} from '../../services/domFunctions';
import * as apiService from "../../services/api.service";
import { getDesignTypeConfiguration } from '../../services/utils.service';
import MediaQuery from 'react-responsive/src'
import { gtmFamily } from '../../services/gtmFamily';
import { Modal } from '../../components/Modal';

const types = ["Vase","Jardinière","Divers","Tous"];

/**
 * Modal permettant de rechercher et choisir un motif à ajouter sur la face courante
 */
class FaccessoryParamsComponent extends Component {

  state = {window:true,loaded:false,accessories:[],currentType:"Tous",accessoryLoading:true, displayMsgConfigMax:false};

  async componentDidMount () {
    if (!this.props.allAccessories){
      const accessories = await apiService.get('/api/catalog/accessories');
      this.props.configActions.storeAccessories(accessories);
    }
    const allAccessories = this.listAccessories();
    const loaded = this.props.current ? true:false;
    this.setState({accessories:allAccessories,loaded:loaded,window:!isSmartphone(),accessoryLoading:false});

    if (getDesignTypeConfiguration((this.props.current && this.props.current.configuration) || null) === "B") {
      document.documentElement.style.setProperty('--fcblue', "#3568ba");
    }
    
    // GTM ( mobile event )
    if ( window.innerWidth <= 768 ) {
      const list = this.state.accessories.map(accessory => {
        let granite = accessory.granites.find( g => g.id == accessory.granite )
        if (granite === undefined) {
          granite = accessory.granites.find( g => g.reference == accessory.defaultgranit )
        }
  
        return {
          reference: accessory.reference,
          type: accessory.kind,
          description: accessory.description,
          details: accessory.details,
          granite: granite.name || "",
          flowersAvailable: accessory.flowersAvailable == "1"
        }
      });
      gtmFamily().customizeConfigurator(
        "configurator.filter",
        "liste accessoires",
        {
          type: "accessoire",
          query: "type",
          filter: "Tous"
        },
        list
      )
    }
    // end GTM
  }

  componentDidUpdate () {
    if (this.props.current) {

      if (!this.state.loaded) {
        const allAccessories = this.listAccessories();
        this.setState({accessories:allAccessories,loaded:true});
      }
    }
  }

  listAccessories (kind = null){
    const { current, allAccessories } = this.props;
  if (current){
    const allGranites = current.options.granites;
    const configuration = current.configuration;
    const fullMonumentGranite = _.find(allGranites, {reference: configuration.monument.graniteMain});
    const isMonumentMadeInFrance = fullMonumentGranite && fullMonumentGranite.madeInFrance;
    let accessories =[];
    if (!kind || kind ==="Tous"){
        accessories = _.chain(allAccessories)
        .filter(accessory => _.some(accessory.granites, {madeInFranceAndImported: true}) || _.some(accessory.granites, {madeInFrance: isMonumentMadeInFrance}))
        .filter({availableInFamilyConfig: 1})
        .value();
      }else{
        accessories = _.chain(allAccessories)
        .filter({kind: kind})
        .filter(accessory => _.some(accessory.granites, {madeInFranceAndImported: true}) || _.some(accessory.granites, {madeInFrance: isMonumentMadeInFrance}))
        .filter({availableInFamilyConfig: 1})
        .value();
      }
    return (accessories);
  }
  else return([]);
  }

  onClicktype = type => {
    const allAccessories = this.listAccessories(type);
    this.setState({accessories:allAccessories,currentType:type});

    // GTM
    const list = allAccessories.map(accessory => {
      let granite = accessory.granites.find( g => g.id == accessory.granite )
      if (granite === undefined) {
        granite = accessory.granites.find( g => g.reference == accessory.defaultgranit )
      }

      return {
        reference: accessory.reference,
        type: accessory.kind,
        description: accessory.description,
        details: accessory.details,
        granite: granite.name || ""
      }
    });
    gtmFamily().customizeConfigurator(
      "configurator.filter",
      "liste accessoires",
      {
        type: "accessoire",
        query: "type",
        filter: type
      },
      list
    )
    // end GTM
  };

  onSelectAccessory = (accessory) => granite => {
    const { current, paramsFamilyConfigMax } = this.props;
    if(current.configuration.accessories.length > parseInt(paramsFamilyConfigMax.FAMILY_CONFIG_MAX_ACC) - 1) {
      this.setState({displayMsgConfigMax: true})      
    } else {
      // GTM
      let productGranite = accessory.granites.find( g => g.reference == accessory.granite )
      if (productGranite === undefined) {
        productGranite = accessory.granites.find( g => g.reference == accessory.defaultgranit )
      }
      const patternAccessory = {
        reference: accessory.reference,
        type: accessory.kind,
        description: accessory.description,
        details: accessory.details,
        flowersAvailable: accessory.flowersAvailable == 1,
        granite: productGranite ? productGranite.name : ""
      }
      gtmFamily().customizeConfigurator(
        "configurator.customize",
        "liste accessoires",
        {
          type: "accessoire",
          query: "réference",
          filter: accessory.reference
        },
        false,
        patternAccessory
      )
      // end GTM
      const { close } = this.props;
      const width = window.innerWidth;
      if (width <= 768){
        close();
      }
      if (isSmartphone()){
        this.setState({window:false})
      };
      this.props.actions.addAccessory(accessory, granite)
    }
  };

  renderItem = (madeInFrance,current) => (accessory) => {
    const {mainColor} = this.props.adminConfig.config
    let className = "";
    if (current){
      if (current.configuration.accessories.find(elt=>elt.reference === accessory.reference)){
        className = "selected";
      }
    }
    return(
      <AccessoryCard key={accessory.reference}
                     configuration={this.props.current.configuration}
                     accessory={accessory}
                     onSelectAccessory={this.onSelectAccessory(accessory)}
                     madeInFrance={madeInFrance}
                     className={className}
                     color={mainColor}
      />
    );
  };

  render() {
    const { accessories, currentType,loaded, accessoryLoading, displayMsgConfigMax } = this.state;
    const { current, backAction, close, paramsFamilyConfigMax } = this.props;
    let isMonumentMadeInFrance = false;
    const infoLabel = accessoryLoading || !loaded ? "Chargement des accessoires en cours":
                                     "Aucun accessoire pour le monument dans ce granit";
    if(current){
    const allGranites = current.options.granites;
    const configuration = current.configuration;
    const fullMonumentGranite = _.find(allGranites, {reference: configuration.monument.graniteMain});
     isMonumentMadeInFrance = fullMonumentGranite && fullMonumentGranite.madeInFrance;
    }
    if(accessories.length && this.props.current &&  this.props.current.configuration) {
      return(
      <div className={`${"TopFaccessory"}`}>
        <MediaQuery maxWidth={768}>
          <OvalAction action={() => close()} text={'Fermer'} frontIcon='close'
                      addClass='accessoryClose' />
        </MediaQuery>
        <div className="FaccessoryParams">
          {current.configuration && (!current.configuration.designType || current.configuration.designType !== "B") &&
            <i className="icon material-icons" onClick={()=>this.setState({window:false})}>&#xE5CD;</i>
          }
          <div className="title">Ajoutez un ou plusieurs accessoires</div>
          <div>
            <div className="types">
              {types.map((type) => (
                <button key={type} onClick={() => this.onClicktype(type)}
                        className={currentType === type ? "selected":""}>
                  {type}</button>
              ))}
            </div>
            {loaded && <ScrollListView items={accessories} renderItem={this.renderItem(isMonumentMadeInFrance,current)} className="AccessoryPicker"/>}
            {/*{current.configuration && current.configuration.designType === "B" &&*/}
            {/*  <div className="accessory-actions">*/}
            {/*    <Button onClick={() => backAction()}>*/}
            {/*      Enregistrer*/}
            {/*    </Button>*/}
            {/*  </div>*/}
            {/*}*/}
          </div>
        </div>
        <Modal
          isOpen={displayMsgConfigMax}
          onRequestClose={() => this.setState({displayMsgConfigMax: false})}
          className="modal-display-msg"
        >
          <p>{`Vous avez atteint le nombre maximum d’accessoires, veuillez supprimer un des ${paramsFamilyConfigMax.FAMILY_CONFIG_MAX_ACC} accessoires pour pouvoir en ajouter un autre.`}</p>
        </Modal>
      </div>)}else{ return (
      <div className="NoAccessory">{infoLabel}</div>
      )}
  }

}
export const FaccessoryParams = connect(
  state => ({
    current: state.configurator.current,
    allAccessories: state.configurator.accessories,
    adminConfig: state.adminConfig,
    paramsFamilyConfigMax: state.user.paramsFamilyConfigMax,
  }),
  dispatch => ({
    uiActions: bindActionCreators(uiActions, dispatch),
    actions: bindActionCreators(updateConfigurationActions, dispatch),
    configActions: bindActionCreators(configurationActions, dispatch),
  })
)(FaccessoryParamsComponent);
