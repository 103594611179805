import React from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {displaySimpleAccesory} from '../../../services/image.fct.service';
import * as _ from 'lodash';
import {Modal} from '../../../components/Modal';
import {GranitePicker} from '../components/GranitePicker'

import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import * as uiActions from '../../../actions/uiActions';

function AccessoryGraniteComponent({
  modalSettings, uiActions, accessories, updateConfigurationActions,
  allAccessories,family,selectedAccessories,engravings,patterns
}) {
  function closeModal() {
    uiActions.chooseGraniteOfAccessories(null);
  }

  function onPickGranite(graniteRef) {
    const updatedAccessories = _.chain(accessories)
      .filter({id: modalSettings.id})
      .map(accessory=> Object.assign({}, accessory, {granite: graniteRef}))
      .value();
      engravings.map(engraving =>{
        if(engraving.face && engraving.face.index == modalSettings.id){
          engraving.face.granit = graniteRef
        }
      });
      patterns.map(pattern =>{
        if(pattern.face && pattern.face.index == modalSettings.id){
          pattern.face.granit = graniteRef
        }
      })
    updateConfigurationActions.updateAccessories(updatedAccessories);
    closeModal();
  }
  let graniteChoices = false;
  let accessory;
  if (modalSettings){
    accessory = _.find(selectedAccessories, {reference: modalSettings.reference});
    graniteChoices = accessory && accessory.granites ? accessory.granites : 
                     allAccessories && allAccessories.length ? _.find(allAccessories, {reference: modalSettings.reference}).granites:false;
  }
    
    if(modalSettings){
        return (
          <Modal
            onRequestClose={closeModal}
            className={family? "FAccessoryGranitePickerPopupModal": "AccessoryGranitePickerPopupModal accessories-granite"}
            overlayClassName={family? "FAccessoryOverlay": "AccessoryGranitePickerPopupModalOverlay"}
            isOpen={!!(modalSettings)}>
            <GranitePicker
              family={family}
              currentGraniteRef={modalSettings && modalSettings.currentGranite}
              onPickGraniteRef={onPickGranite}
              customGranites={graniteChoices}
              accessoryRef={accessory.reference || null}
              // suggestionImageURL={
              //   graniteRef=>displaySimpleAccesory(modalSettings && modalSettings.reference, graniteRef,"thumbnail")}
              title={family?"Choisissez un granit à appliquer sur cet accessoire":
                "Choisissez un granit à appliquer sur tous les accessoires de ce type"}/>
          </Modal>
        )
    }else{
      return <div/>
    }

}

export const AccessoryGranite = connect(state => ({
    modalSettings: state.ui.accessoryGraniteModalParameters,
    accessories: state.configurator.current.configuration.accessories,
    selectedAccessories: state.configurator.current.accessories,
    allAccessories: state.configurator.accessories,
    engravings: state.configurator.current.configuration.engravings,
    patterns: state.configurator.current.configuration.patterns
  }), (dispatch)=>({
    uiActions: bindActionCreators(uiActions, dispatch),
    updateConfigurationActions: bindActionCreators(updateConfigurationActions, dispatch),
  })
)(AccessoryGraniteComponent);
