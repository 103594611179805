import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as configurationActions from '../../../actions/configurationActions';
import * as catalogActions from '../../../actions/catalogActions';
import {Carousel} from '../../../components/carousel';
import {Ffooter} from '../../family/Ffooter';
import { GranitePreviewCard } from './GranitePreviewCard';


class GraniteCarouselComponent extends Component {

 

  renderMonument = (granite, index) => {
    const {monument, frame} = this.props.configuration;
    return (
      <div>
          {this.props.ffooter &&  <Ffooter previous={elt=>this.props.previous(monument)} next={elt=>this.props.next(granite.reference)}
          ptext={this.props.ptext} ntext={this.props.ntext} /> }
              <figure className="FullSize Monument">
              <GranitePreviewCard key={granite.reference} monument={monument} granite={granite}
                          frameMode={frame.mode} lazy={false} pattern={true}
                          onSelectGranite={function(){}} 
                          config= {this.props.configuration}
                          price={true} large={true}/>                
              </figure>
      </div>
    )
  };

  render() {
    return <Carousel
      slideToShow={this.props.slideToShow}
      slidesData={this.props.granites}
      renderSlide={this.renderMonument}
      renderClass={(monument) => "Category-" + monument.category}
      className="GraniteCarousel"
      textRight="Granit suivant"
      textLeft="Granit précédent"
    />
  }
}

export const GraniteCarousel = connect((state) => ({
  configuration: state.configurator.current.configuration,
    catalog: state.catalog,
    monuments: state.catalog.visibleMonuments,
  }),
  (dispatch) => ({
    configurationActions: bindActionCreators(configurationActions, dispatch),
    catalogActions: bindActionCreators(catalogActions, dispatch)
  })
)(GraniteCarouselComponent);
