import React, {Component} from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import {ListView} from './ListView';

/**
 * Composant qui encapsule une ListView dans un container scrollable.
 */
export class ScrollListView extends Component {
  static propTypes = {
    items: PropTypes.array.isRequired, // Liste de items à afficher
    renderItem: PropTypes.func.isRequired, // Fonction retournant le rendu pour un item de la liste
    header: PropTypes.any, // Contenu se trouvant au dessus de la partie scrollable
    footer: PropTypes.any, // Contenu se trouvant en dessous de la partie scrollable
    scrollableHeader: PropTypes.any, // Contenu se trouvant dans la partie scrollable et au dessus la
                                     // liste
    scrollableFooter: PropTypes.any, // Contenu se trouvant dans la partie scrollable et en dessous la
                                     // liste

    className: PropTypes.any,
    headerClassName: PropTypes.any,
    footerClassName: PropTypes.any,
    scrollableClassName: PropTypes.any,
  };

  state = {};

  render() {
    const {
      className, scrollableClassName, scrollableHeader, scrollableFooter, ...other
    } = this.props;
    return (
      <div className={cn('ScrollListView', className)}
           ref={(_elt) => {if (!this.state.scrollableElement) this.setState({scrollableElement: _elt})}}>

        {/* Scrollable list */}
        <div className={cn('Scrollable', scrollableClassName)}>

          {scrollableHeader}

          {this.state.scrollableElement && (
            <ListView scrollableElement={this.state.scrollableElement} {...other}/>
          )}

          {scrollableFooter}

        </div>
      </div>
    );
  }
}
