import React, { Component } from "react";
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import { bindActionCreators } from "redux";
import * as updateConfigurationActions from "../../../actions/updateConfigurationActions";
import * as _ from "lodash";
import cn from "classnames";
import { InputNumber } from "../../../components/InputNumber";
import InputRange from "../../../components/InputRange";
import {getEngravingAvailableInfo} from "../../../services/generateEngravings.service";
import {pickOne,onSelectKind,onSelectColor,onSelectFamily,onChangeSize,onChangeLineHeight} from "../../../services/commonEngraving.service";
import { TranslationContext } from "../../../context/TranslationContext";
import { Modal } from '../../../components/Modal'
import * as api from '../../../services/api.service'

class EngravingParamsComponent extends Component {
  static contextType = TranslationContext;
  static propTypes = {
    engravings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
    engravingTypes: PropTypes.any.isRequired,
    engravingsId: PropTypes.arrayOf(PropTypes.string).isRequired,
    calculateEngravingsLines: PropTypes.func.isRequired,
    onClose: PropTypes.func
  };

  state = {
    modalVisible: false,
    modalMessage: ""
  }

  render() {
    const t = this.context;
    const { engravings, engravingTypes, onClose,adminConfig,user } = this.props;

    const kind = pickOne("font.kind",this.props);
    const color = pickOne("font.color",this.props);
    const family = pickOne("font.family",this.props);
    const size = pickOne("font.size",this.props);
    const lineHeight = pickOne("font.lineHeight",this.props);
    const listEngravings = getEngravingAvailableInfo(user,engravingTypes,kind,color,family,adminConfig.engravingsToHide);

    const getBalckEngravingAlert = async() => {
      const errorMessage = t("config3d_engraving_warning_modal") || "Attention, le choix d'une gravure noire peut, sur certains granits veinés en noire, conduire à un manque de visibilité des caractères."
      this.setState({modalVisible: true, modalMessage: errorMessage}) 
    }

    return (
      <section
        className={cn("EngravingParameters background-page", {
          active: engravings && engravings.length
        })}
      >
        <div className="Headline">
          <h5>
            {/* Éditer {engravings.length > 1 ? "les gravures" : "la gravure"} */}
            { t("config3d_patternandengravings_modal_engravings_title") || "Editer la gravure" }
          </h5>
          <div className="Close" href="#" onClick={onClose}>
            <span className={`close-icon icon icon-medium`}></span>
          </div>
        </div>

        <div className="Row">
          <label className="Kind">
            <span className="Label">
              { t("config3d_patternandengravings_modal_engravings_type_title") || "Type de gravure" }
            </span>
            <div className="Field">
              <select value={kind || ""} onChange={e=>onSelectKind(e,this.props)}>
                {!kind && <option value="">----</option>}
                {_.map(listEngravings.types, (type) => (
                  <option key={type} value={type}>
                    {type}
                  </option>
                ))}
              </select>
            </div>
          </label>
        </div>

        <div className="Row" style={{
          marginTop: "-0.313rem"
        }}>
          <label className="Color">
            <span className="Label">
              { t("config3d_patternandengravings_modal_engravings_color_title") || "Couleur" }
            </span>
            <div className="Field ColorPicker">
              {listEngravings.colors.map((item) => (
                  <div
                    key={item.id}
                    onClick={async () => {
                      onSelectColor(item.id,this.props); 
                      item.id === "#000000" && await getBalckEngravingAlert()
                    }}
                    className={cn("Color", { active: listEngravings.selectedColor && listEngravings.selectedColor.id === item.id })}
                    style={{ backgroundColor: item.id }}
                  >{item.label}</div>
                )
              )}
            </div>
          </label>
        </div>

        <div className="Row">
          <label className="FontSize">
            <span className="Label">
              { t("config3d_patternandengravings_modal_engravings_size") || "Taille des caractères (mm)" }
            </span>
            <div className="Field">
              <InputRange
                value={size}
                min={15}
                max={60}
                step={1}
                onValueChange={(value)=> onChangeSize(value,this.props)}
              />
            </div>
          </label>
          
          {/* <label className="LineHeight">
            <span className="Label">Interligne</span>
            <div className="Field">
              <InputNumber
                value={lineHeight}
                min={0}
                max={20}
                step={1}
                onValueChange={(value)=> onChangeLineHeight(value,this.props)}
              />
              <span className="Unit">mm</span>
            </div>
          </label> */}
        </div>

        {/* 
        <div className="Row">
          <label className="Font">
            <span className="Label">Police</span>
            <div className="Field FamilyPicker">
              {listEngravings.fonts.map((item) => (
                <div
                  key={item.id}
                  onClick={() => onSelectFamily(item.id,this.props)}
                  className={cn("Family", { active: listEngravings.selectedFont.id  === item.id })}
                >
                  <span style={{ fontFamily: item.data.family }}>{item.id}</span>
                  <i className="icon material-icons">&#xE876;</i>
                </div>
              ))}
            </div>
          </label>
        </div> 
        */}

        <div className="Row" style={{
          marginTop: "-0.313rem"
        }}>
          <label className="Font">
            <span className="Label">
              { t("config3d_patternandengravings_modal_engravings_font_title") || "Police" }
            </span>
            <div className="Field FamilyPicker">
              {listEngravings.fonts.map((item) => (
                <div
                  key={item.id}
                  className={cn("Family", { active: listEngravings.selectedFont.id === item.id })}
                  onClick={() => onSelectFamily(item.id, this.props)}
                >
                  <label style={{ fontFamily: item.data.family }} htmlFor={`fontCheckbox_${item.id}`}>{item.id}</label>
                  <div className="round">
                    <input
                      id={`fontCheckbox_${item.id}`}
                      type="checkbox"
                      checked={listEngravings.selectedFont.id === item.id}
                    />
                    <label for="checkbox"></label>
                  </div>
                </div>
              ))}
            </div>
          </label>
        </div>

        <Modal
          isOpen={this.state.modalVisible}
          onRequestClose={() => this.setState({modalVisible: false})}
          className='engraving-alert'
          >
            <p>{this.state.modalMessage}</p>
            <button 
              class="text-cta secondary1 custom-secondary-button button-primary button-small text-cta"
              onClick={() => this.setState({modalVisible: false})}
              >
                OK
            </button>
        </Modal>
      </section>
    );
  }
}

export const EngravingParams = connect(
  (state, ownProps) => ({
    engravings: state.configurator.current.configuration.engravings.filter(
      engraving => ownProps.engravingsId.indexOf(engraving.id) >= 0
    ),
    engravingTypes: state.configurator.current.options.engravingTypes || {},
    adminConfig: state.adminConfig.config,
    user:state.user
  }),
  dispatch => ({
    actions: bindActionCreators(updateConfigurationActions, dispatch)
  })
)(EngravingParamsComponent);
