import React, {Component} from 'react';
import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import {displaySimpleAccesory} from '../../../services/image.fct.service';
import * as _ from 'lodash';
import {ModalButton} from '../../../components/ModalButton';
import {GranitePicker} from '../components/GranitePicker';
import * as updateConfigurationActions from '../../../actions/updateConfigurationActions';
import { isConfigRequested } from "../../../services/configurationWarningChecks"

import cn from 'classnames';
import { TranslationContext } from "../../../context/TranslationContext";

class AccessoriesGranitePickerComponent extends Component {
  static contextType = TranslationContext;
  state = {
    activeTabIndex: 0
  }
  modal;
  pickTab = (activeTabIndex)=>this.setState({activeTabIndex})

  render() {
    const t = this.context;
    const {
      accessories,
      allAccessories,
      allAccessoriesWithAcier,
      updateConfigurationActions,
    } = this.props;
    const { activeTabIndex } = this.state;

    const accessoriesGroups = _.chain(accessories)
      .map(({reference, granite})=>{
        const accessoryInfos = reference.startsWith("ACI") ? _.find(allAccessoriesWithAcier, {reference: reference}) : _.find(allAccessories, {reference: reference});
        return {
          reference,
          description: accessoryInfos && accessoryInfos.description,
          granite,
          granites: accessoryInfos && accessoryInfos.granites,
        };
      })
      .value().sort((a, b) => b.granites.length - a.granites.length);

    // Le tab actif est conservé quand la modale est fermée,
    // donc dans le cas ou on supprime des accesoires le activeTabIndex
    // peut etre plus grand que le nombre de tabs (d'où le modulo).
    const activeTabParams = accessoriesGroups[
      this.state.activeTabIndex];

    const onPickGraniteForTab = (graniteRef) => {
      const updatedAccessories = _.chain(accessories)
        .filter((accessory, index)=>index === activeTabIndex)
        .map(accessory=> Object.assign({}, accessory, {granite: graniteRef}))
        .value();
      updateConfigurationActions.updateAccessories(updatedAccessories);
      this.modal.close();
    }
    const {config, productFrom} = this.props
    const category = config.monument.category
    const disabled = !accessories.length || isConfigRequested()  

    return (
      <div className={"AccessoryGranitePicker btn-wrapper"}>
        <ModalButton buttonContent={category ==='ACC' ? t("config3d_accessories_button_granit") : t("config3d_granit_button_accessories")}
                     buttonIcon={category !=='ACC' ? "accessory-icon" : "granite-icon"}
                     className="AccessoriesGranitePickerPopupModal buttonModal background-page accessories-granite"
                     overlayClassName="AccessoriesGranitePickerPopupModalOverlay"
                     disabled={disabled}
                     buttonClassName={`btn-config-secondary text-dark config-button-background ${!disabled && 'config-button-hover'}`}
                     toggleSteps={this.props.toggleSteps}
                     launchIntro={this.props.launchIntro}
                     madalName={this.props.madalName}
                     ref={(_modal) => this.modal = _modal}
                    >
            <div className="Header">
              <div className="HeaderContainer">
                <h1 className="Headline">
                  { t("config3d_granit_modal_accessories_title") || "Choisissez un granit à appliquer sur l'accessoire sélectionné" }
                </h1>
              </div>
              <AccessoriesTabs accessoriesGroups={accessoriesGroups}
                              activeTabIndex={this.state.activeTabIndex}
                              pickTab={this.pickTab}/>
            </div>
          <div className="AccessoriesGraniteTabContent">
            {activeTabParams &&
            <GranitePicker
              activeTabParams={activeTabParams}
              currentGraniteRef={activeTabParams.granite}
              onPickGraniteRef={onPickGraniteForTab}
              customGranites={activeTabParams.granites}
              // suggestionImageURL={graniteRef=>displaySimpleAccesory(activeTabParams.reference, graniteRef,"thumbnail")}
              activeTabIndex={this.state.activeTabIndex}
              accessoryRef={activeTabParams.reference}
            />
            }
          </div>
        </ModalButton>
      </div>
    )
  }
}

function AccessoriesTabs({accessoriesGroups, activeTabIndex, pickTab}) {
  return (
    <div className="AccessoriesGranitePickerTabs">
      {
        accessoriesGroups.map((group, index)=>(
          <div className={cn('Tab', 'Accessory', {active: index === activeTabIndex})}
             key={index}
             onClick={()=>pickTab(index)}>

            <div className="Reference">{group.reference}</div>
            <div className="Description">{group.description}</div>
          </div>
        ))
      }
    </div>
  )
}

export const AccessoriesGranitePicker = connect(state => ({
    config: state.configurator.current.configuration,
    accessories: state.configurator.current.configuration.accessories,
    allAccessories: state.configurator.current.accessories,
    allAccessoriesWithAcier: state.configurator.accessories,
    productFrom: state.configurator.productFrom
  }), (dispatch)=>({
    updateConfigurationActions: bindActionCreators(updateConfigurationActions, dispatch),
  })
)(AccessoriesGranitePickerComponent);
