import React from 'react';
import PropTypes from 'prop-types';
import { LazyImage } from '../../../components/LazyImage';
import { displaySimpleAccesory } from '../../../services/image.fct.service';
import * as _ from 'lodash';

/**
 * Composant affichant un accessoire dans la popup de choix
 */
export function AccessoryCard({configuration, accessory, onSelectAccessory, madeInFrance,className, color}) {
  /* Tous les accessoires d'une même référence doivent avoir le même granit.
   Donc on vérifie avant d'afficher la thumbnail que le granit n'est
   pas forcé par la présence dans la config d'un accessoire de la même référence .
   */
  const alreadyPlaced = _.find(
    configuration.accessories,
    { reference: accessory.reference });
  /*
   Si on trouve un accessoire de cette référence avec un granit défini,
   on génère une thumbnail avec le même granit.
   */
  let thumbnailGranite = (alreadyPlaced && alreadyPlaced.granite)
    || configuration.monument.graniteMain;

  // On utilise le granit Noir Fin si l'accessoire n'est pas dispo dans le granit du monument
  if (!_.find(accessory.granites, { reference: thumbnailGranite })) {
    if (madeInFrance) {
      // On est sur de trouver un granite madeInFrance car sinon l'accessoire n'est pas affiché par le picker.
      if(_.find(accessory.granites, {madeInFrance}))
        thumbnailGranite = _.find(accessory.granites, {madeInFrance}).reference;
    } else {
      thumbnailGranite = accessory.reference === "A163" ? "PQ" : 'NF'; // Si  l'accessoire est (A163) Lierre synthétique alors on force la texture à plasitque
    }
  }
  /* Ajouter des condition d'application du granit correspondant */
  /*
    ! Si le granit principal du monument est disponible pour l'accessoire
      ?===> Appliquer le granit principal
    ! Sinon si le granit secondaire du monument est disponible pour l'accessoire
      ?===> Appliquer le granit secondaire
    ! Sinon si le granit par défaut de l'accessoire est disponible sur la liste des accessoires
      ?===> Appliquer le granit par défaut
    ! Sinon
      ?===> Appliquer le 1er granit disponible de la liste des granits de l'accessoire.
  */
  if (configuration.monument.kind !== 'ACC') {
    if (_.find(accessory.granites, { reference: configuration.monument.graniteMain })) {
      thumbnailGranite = configuration.monument.graniteMain
    } else if (_.find(accessory.granites, { reference: configuration.monument.graniteSecondary })) {
      thumbnailGranite = configuration.monument.graniteSecondary
    } else if (_.find(accessory.granites, { id: accessory.granite })) {
      const thumbGranite = accessory.granites.filter(gr => accessory.granite === gr.id)
      thumbnailGranite = thumbGranite[0].reference
    }else if (accessory.defaultgranit && _.find(accessory.granites,{reference: accessory.defaultgranit})) {
      thumbnailGranite = accessory.defaultgranit
    }else{
      thumbnailGranite =  accessory.granites[0].reference
    }
  }

  const theClass = className ? "AccessoryCard " + className : "AccessoryCard";

  if(configuration.monument.category==='ACC' ){
    if (_.find(accessory.granites,{id: accessory.granite})){
      const thumbGranite = accessory.granites.filter(gr => accessory.granite === gr.id)
      thumbnailGranite = thumbGranite[0].reference
    }else if (_.find(accessory.granites,{reference: accessory.defaultgranit})){
      thumbnailGranite = accessory.defaultgranit
    }else{
      thumbnailGranite = accessory.granites[0].reference
    }

  }
  return (
    <div className={theClass} onClick={() => onSelectAccessory(thumbnailGranite)} style={{"--f-main-color": color}}>
      <LazyImage src={displaySimpleAccesory(accessory.reference,thumbnailGranite)} lazy={false}/>
      <div className="Infos">
        <p className="Reference">{accessory.reference}</p>
        <h1 className="Description">
          {accessory.description} {!accessory.description.includes('✍️') && <span style={{ visibility: 'hidden' }}> ✍️</span>}
        </h1>
      </div>
    </div>
)
}

AccessoryCard.propTypes = {
  configuration: PropTypes.shape({
    monument: PropTypes.shape({
      graniteMain: PropTypes.string.isRequired,
    }).isRequired,
  }).isRequired,
  accessory: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    name: PropTypes.string.isRequired,
    granites: PropTypes.array.isRequired,
  }).isRequired,
  onSelectAccessory: PropTypes.func.isRequired,
  madeInFrance: PropTypes.bool.isRequired,
};
