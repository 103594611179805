import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {LazyImage} from '../../../components/LazyImage';
import {imgs} from '../../../services/images.service';
import cn from 'classnames';
import * as _ from 'lodash';

/**
 * Composant affichant une stèle dans la popup de choix
 */
export class HeadstoneCard extends Component {

  onClick = () => {
    this.props.onSelectHeadstone(this.props.headstone);
  };

  render() {
    const {configuration, headstone} = this.props;
    const selected = (configuration.monument.headstone.reference === headstone.reference);
    const disabled = !_.find(headstone.granites, ({reference}) => reference === configuration.monument.graniteMain);
    return (
      <div className={cn('HeadstoneCard', {selected, disabled})} onClick={ !selected && !disabled ? this.onClick :undefined }>
        <LazyImage src={imgs.configuration.monument.iso.thumbnail({
          monument: configuration.monument,
          frame: configuration.frame,
          headstoneRef: headstone.reference
        }, {
          excludeDefaultPattern: false,
        })} lazy={false}/>
        {disabled && (
          <div className="DisabledMessage">
            <p>Cette stèle n'est pas compatible avec le granit du monument.</p>
          </div>
        )}
        <div className="Infos">
          <h3 className="Name">
            {headstone.name}
          </h3>
          {/* {
            headstone.featured && (
              <span className="Star" title="Combinaison présentée dans notre collection favorite">
                <i className="icon material-icons">&#xE838;</i>
              </span>
            )
          } */}
        </div>
      </div>
    )
  }
}

HeadstoneCard.propTypes = {
  configuration: PropTypes.shape({
    monument: PropTypes.shape({
      graniteMain: PropTypes.string.isRequired,
      headstone: PropTypes.shape({
        reference: PropTypes.string.isRequired,
      }).isRequired,
    }).isRequired,
  }).isRequired,
  headstone: PropTypes.shape({
    reference: PropTypes.string.isRequired,
    granites: PropTypes.arrayOf(PropTypes.shape({
      reference: PropTypes.string.isRequired,
    })).isRequired,
  }).isRequired,
  onSelectHeadstone: PropTypes.func.isRequired,
};
