import React, {Component} from 'react';
import cn from 'classnames';

import {connect} from 'react-redux';
import {bindActionCreators} from 'redux';
import * as configurationActions from '../../../actions/configurationActions';
import {FavoriteStar} from './FavoriteStar';
import {LazyImage} from '../../../components/LazyImage';
import * as catalogActions from '../../../actions/catalogActions';

import {getMonumentImage, createMonumentImage} from '../../../services/image.fct.service';
import {Carousel} from '../../../components/carousel';
import {getFootstoneCode} from '../../../services/format.service';
import {getDescription} from "../../../services/utils.service";
import {Ffooter} from '../../family/Ffooter';
import * as apiService from "../../../services/api.service";
import { ScrollListView } from '../../../components/ScrollListView'
// import * as _ from 'lodash';
const ShoppingCartRoundedRedImage = require("../../../images/ShoppingCartRoundedRed.svg");
const ShoppingCartRoundedBlueImage = require("../../../images/ShoppingCartRoundedBlue.svg");
const menuGridImage = require("../../../images/gg_menu-grid-r.svg");
const loadingImage = require("../../../images/loader@4x.gif");


class MonumentCarouselComponent extends Component {

  state = {
    initializing: false,
    granits: [],
    showGranits: false,
    granitsPrices: []
  };

  componentDidMount() {
    // Get Cranits
    if (this.state.granits.length == 0) {
      apiService.get('/api/catalog/granits').then(result => this.setState({
        granits: result.reduce((r, o) => (r[o.id] = {reference: o.reference, name: o.name}, r), [])
      })).catch(err => console.log(err));
    }
  }

  initConfiguration = (monument) => {
    const {configurationActions, loadingPrices} = this.props;
    if (loadingPrices) {
      loadingPrices();
    }
    this.setState({
      initializing: true,
    });
    this.filterLayouts(monument);
    configurationActions.initConfiguration(monument,this.props.config,false,false,"",monument.graniteMain.reference);
  };

  initConfigurationWithGranit = (monument,config,graniteRef) => {
    const {configurationActions, loadingPrices} = this.props;
    if (loadingPrices) {
      loadingPrices();
    }
    this.setState({
      initializing: true,
    });
    this.filterLayouts(monument);
    configurationActions.initConfiguration(monument,config,false,false,"",graniteRef);
  };

  filterLayouts = (monument)=>{
    const {configurationActions} = this.props;
    const filteredLayouts = this.props.catalog.filters.layouts;
    if (!monument.layouts || monument.layouts.length === 0 || !filteredLayouts || filteredLayouts[0] === "all" ){
      return;
    }
    // Il faudra filtrer les recettes pour montrer une recette du filtre la première fois
    configurationActions.filteredLayouts(true);

  }

  showGranits = (monument) => {
    const orgRef = this.props.organizationReference;
    if (!this.state.showGranits && orgRef) {
      apiService.get(`/api/catalog/monuments?granitsprices=1&client=${orgRef}&references=${monument.reference}`).then(_monument => {
        let _granitsprices = [];
        if (_monument && _monument[0]) {
          _granitsprices = _monument[0].granitsprices;
        }
        _granitsprices = _granitsprices.map(elt => {
          const {reference, name} = this.state.granits[elt.granite] || [];
          return ({...elt, name: name, reference: reference});
        });
        // Place main granite on top
        let _finalData = _granitsprices.filter(
          (elt) => elt.reference !== monument.graniteMain.reference
        )
        //Order Alph A-Z
        _finalData.sort(function(a, b){
          if(a.name < b.name) { return -1; }
          if(a.name > b.name) { return 1; }
          return 0;
        });
        //Find the main monument granit
        const monumentGranite = _granitsprices.find(
          (elt) => elt.reference === monument.graniteMain.reference
        )
        //Add the main monument granit in the first
        if (monumentGranite) {
          _finalData.unshift(monumentGranite);
        }
        this.setState({ granitsPrices: _finalData });
      }).catch(err => console.log(err));
    }
    this.setState({
      showGranits: !this.state.showGranits,
    });
  };

  renderItem = (monument) => (granite, index) => {
    const {reference, name} = granite || [];
    const priceHt = granite.priceDiscounted ? new Intl.NumberFormat('fr-FR').format(Math.ceil(granite.priceDiscounted)):'---';
    const priceTtc = granite.priceTtc ? new Intl.NumberFormat('fr-FR').format(Math.ceil(granite.priceTtc)):'---';
    const selected = monument.graniteMainId == granite.granite? 'selected': '';

    return (
      <div
        className={cn([
          'GraniteCard',
          'GranitePreviewCard',
          selected
        ])}
      >
        <div
          className={cn(['GraniteCardContent', 'CatalogGraniteCardContent'])}
          onClick={() => this.initConfigurationWithGranit(monument,this.props.config,reference)}
        >
            <LazyImage lazy={false}
            src={createMonumentImage(monument, reference,"thumbnail")}
            lowResImage={createMonumentImage(monument, reference,"thumbnail")}/>
            
            <div className='Infos'>
              <h3 className='Name'>{name}</h3>
              <div className="Price">
                <span className="Priceht"><img src={ShoppingCartRoundedRedImage} style={{display: "inline-block", width: "18px", height: "16px"}}/> {priceHt? <span style={{display: "inline-block"}}>{priceHt}€* <small>HT</small></span>:<span><img src={loadingImage} style={{width: "20px", height: "20px"}} alt="Chargement"/></span>}</span>
                <span className="Pricettc"><img src={ShoppingCartRoundedBlueImage} style={{display: "inline-block", width: "18px", height: "18px"}}/> {priceTtc? <span style={{display: "inline-block"}}>{priceTtc}€* <small>TTC</small></span>:<span><img src={loadingImage} style={{width: "20px", height: "20px"}} alt="Chargement"/></span>}</span>
              </div>
            </div>

        </div>
      </div>
    );
  }

  renderMonument = (monument, index) => {
    let footstoneName = `GPG ${getFootstoneCode(monument.footstone)}`;
    const monumentCata = false
    if (monument.catalog && monument.catalog.name !== "") {
      footstoneName = monument.catalog.name
      monumentCata = true
    }
    const headstoneName = monument.headstone ? `Stèle ${monument.headstone}` : '';
    const patternName = monument.pattern ? `motif ${monument.pattern}` : '';
    const graniteName = monument.graniteMain.name +
      (monument.graniteSecondary.name ? ` et ${monument.graniteSecondary.name}` : '');
    //const hasFrame = monument.category !== '900-999';
    const hasFrame = "frame" in monument && monument.frame == "false" ? false : true
    const description = getDescription(monument);
    const {showPrices, prices} = this.props;
    const priceHt = prices && prices[monument.reference] ? (parseInt(prices[monument.reference].discounted)>0? new Intl.NumberFormat('fr-FR').format(Math.ceil(prices[monument.reference].discounted)):'---'):0;
    const priceTtc = prices && prices[monument.reference] ? (parseInt(prices[monument.reference].selling)>0 ? new Intl.NumberFormat('fr-FR').format(Math.ceil(prices[monument.reference].selling)):'---'):0;
    
    return (
      <div>
        {this.state.showGranits && <div style={{margin: "20px"}}>
          <ScrollListView
                items={this.state.granitsPrices}
                renderItem={this.renderItem(monument)}
                className='GlobalGranitePicker'
              /> 
        </div> }
        {!this.state.showGranits && <div>
          <p style={{fontSize: "13px", textAlign: "right", marginRight: "40px"}}>*Prix hors semelle, placage, accessoires et motifs</p>
          
          <figure className="FullSize Monument">
            <LazyImage lazy={false}
                        src={getMonumentImage(monument,"large")}
                        lowResImage={getMonumentImage(monument,"thumbnail")}/>
          </figure>
          
          <div className="Footer">
            <div className="Infos">
              <h1>
                <span className="Footstone">{footstoneName}</span>
                {headstoneName && (<span className="Headstone">{headstoneName}</span>)}
                {description && (<span className="Description">- {description}</span>)}
              </h1>
              {monument.price && (<div className="Price">A partir de {monument.price}€</div>)}
              <p className="Granite">
                Présenté
                en {graniteName} {(patternName || hasFrame) ? 'avec' : ''} {patternName} {(patternName &&
              hasFrame) ? 'et' : ''} {hasFrame ? 'semelle' : ''}
              </p>
            </div>
            <div className="Infos">
              {showPrices && <div className="Price">
                <span className="Priceht"><img src={ShoppingCartRoundedRedImage} style={{width: "20px", height: "20px"}}/> {priceHt? <span>{priceHt}€ <small>HT</small></span>:<span><img src={loadingImage} style={{width: "20px", height: "20px"}} alt="Chargement"/></span>}</span>
                <span className="dot"></span>
                <span className="Pricettc"><img src={ShoppingCartRoundedBlueImage} style={{width: "20px", height: "20px"}}/> {priceTtc? <span>{priceTtc}€ <small>TTC</small></span>:<span><img src={loadingImage} style={{width: "20px", height: "20px"}} alt="Chargement"/></span>}</span>
              </div>}
            </div>
            {!this.props.ffooter && <div className="Actions">
              {showPrices && 
                <button className={cn('FavoriteStar')}
                        onClick={() => this.showGranits(monument,this.props.config)}>
                  Vue multigranits
                  <img src={menuGridImage} style={{width: "18px", height: "18px", verticalAlign: "bottom", marginLeft: "6px" }}/>
                </button>
              }
              <FavoriteStar mode="button" reference={monument.reference}/>
              { !this.state.initializing? (
                <button className={cn('InitConfiguration')} onClick={() => this.initConfiguration(monument,this.props.config)}>
                  Configurer ce monument
                  <i className="icon material-icons">&#xE409;</i>
                </button>
              ): (
                <button className={cn('InitConfiguration', 'disabled')}
                        disabled={true}>
                  Création en cours...
                  <i className="icon material-icons">&#xE88B;</i>
                </button>
              ) }
            </div>}
          </div>
          {this.props.ffooter && <Ffooter previous={elt=>this.props.previous()} next={elt=>this.props.next(monument)}
            ptext={this.props.ptext} ntext={this.props.ntext}
            similar = {monument.similar} mid ={elt=>this.props.mid(monument)} mtext={this.props.mtext}/>}
        </div> }
      </div>
    )
  };

  render() {
    return <Carousel
      slideToShow={this.props.slideToShow}
      slidesData={this.props.monuments}
      renderSlide={this.renderMonument}
      renderClass={(monument) => "Category-" + monument.category}
      className="MonumentCarousel"
      textRight={this.props.textRight}
      textLeft={this.props.textLeft}
    />
  }
}

export const MonumentCarousel = connect((state) => ({
    catalog: state.catalog,
    monuments: state.catalog.visibleMonuments,
  }),
  (dispatch) => ({
    configurationActions: bindActionCreators(configurationActions, dispatch),
    catalogActions: bindActionCreators(catalogActions, dispatch)
  })
)(MonumentCarouselComponent);
