import React, {useContext} from "react";
import Select from 'react-select';
import MediaQuery from "react-responsive/src";
import {getStore} from '../../store';
import {hexToRgb} from "../../services/utils.service";
import { ReactSearchAutocomplete } from 'react-search-autocomplete';
import { TranslationContext } from "../../context/TranslationContext";
import {initialChoiceMade} from '../../actions/uiActions';
import { useDispatch } from 'react-redux'

const getList=(theColors) =>{
  let tab = [];
  tab.push({label:"Tous",value:null});
  theColors && theColors.forEach(color=> tab.push({label:color,value:color}));
  return tab;
}
const getSelectedColor = (color)=>{
  if (color) return {label:`Filtrer: ${color}`,value:color};
  else return {label:`Filtrer: Tous`,value:color};
};

const changeSelect = (option,filter)=> filter(option.value);

export const FgraniteColors=({filter,currentColor, currentSearch,hideTitle,items, designType, family, mainColor})=>{
    let searched = currentSearch;
   
    const handleOnSearch = (string, results) => {
      searched = string;
    }
  
    const handleOnSelect = (item) => {
      // the item selected
      searched = item.name;
      filter(null,searched);
    }
  
    const handleOnClear = () => {
      // the item selected
      searched = null;
      filter(null,searched);
    }

    const onSearchGraniteBtn = () => {
      // get autocomplet input
      const inputFeild = document.querySelector('._FfilterGranit input').value;
      
      if ( inputFeild && inputFeild.length > 1 && searched && searched.length > 1) {
        filter(null, searched);
      } else {
        handleOnClear();
      }
    }

    let theColors = getStore().getState().configurator.current.options.granitcolors
    theColors && theColors.map(elt => {
      // colorinrgb[colorinrgb.indexOf(Math.max(...colorinrgb))] -= 15
      let colorinrgb = hexToRgb(elt.color)
      const color = "rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",1)";
      const color2 ="rgb("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+",0.4)"
      colorinrgb = colorinrgb.map(x => x - 75);
      const color1 ="rgba("+colorinrgb[0]+","+colorinrgb[1]+","+colorinrgb[2]+", 0.9)"
      elt.backcolor = "linear-gradient(0deg,"+color1+" 3%, "+color+" 40%, "+color2+" 90%)"
    })

    const t = useContext(TranslationContext);
    return (
      <div className="fGraniteColorsContainer">
        <div className={`FfilterCatalog GraniteColors colors${designType ? designType : 'X'}`}>
          <div className="fFilterGraniteContainer">
              {/* <div className={`${designType !="B" ? 'flex-25' : ''}`}></div> */}
              {!designType && <div className={`_FfilterGranit ${!designType ? 'flex-25' : ''}`}>
                <div style={{width: "250px"}}>
                    <ReactSearchAutocomplete
                      items={items}
                      onSearch={handleOnSearch}
                      onSelect={handleOnSelect}
                      onClear={handleOnClear}
                      inputSearchString={searched || ''}
                      placeholder={ t('config3d_monument_modal_granit') || "GRANIT..." }
                      showIcon={true} 
                      styling={{ 
                        height: "31px", 
                        boxShadow: "none",
                        border: "1px solid #D1D5DB",
                        borderRadius: "7px"
                      }}
                      fuseOptions={{ caseSensitive: false , threshold: 0.2, sortFn: (a, b) => (a.name - b.name), minMatchCharLength: 2, keys: [ "name", "name2"] }}
                      autoFocus
                    />
                </div>
                {/* <button className="AllColors" onClick={() => onSearchGraniteBtn()}>Rechercher</button> */}
              </div>}

              <div className={`${!designType ? 'flex-75 d-flex align-items-center flex-wrap justify-content-center' : 'flex-100'}`}>
                {!hideTitle && <div className="TitrePage">Sélectionnez un granit</div>}
                { theColors && 
                  theColors.map(elt => 
                    <button key={elt.name} 
                      className={`colorFilter ${currentColor == elt.name ? 'current' : ''} ${!family ? 'extrColor' : 'famColor'}`} 
                      style={{backgroundColor: elt.color}}
                      onClick={()=> {
                        searched = null;
                        filter(elt.name,searched)
                      }}
                      data-gtm={elt.name}>
                        {designType ? elt.name : ''}
                    </button>
                  )
                }
                <button 
                  className="reset-filter text-cta secondary1 custom-secondary-button button-primary button-small text-cta" 
                  onClick={()=>
                    handleOnClear()
                    // filter(null,searched)
                  }>
                    réinitialiser
                  </button>
                <span className="tooltip top" tooltip-text="Appliquer un granit à tous le catalogue" style={{paddingTop: '4px'}}>
                  <span className="infos-icon icon-large secondary2-icon"></span>
                </span>
              </div>
          </div>

        </div>
        {/*<div> */}
        {/*<Select className="selectColor" value={getSelectedColor(currentColor)} options={getList(theColors)}*/}
        {/*  onChange={(option)=>changeSelect(option,filter)}  isSearchable={false}/>*/}
        {/*</div>*/}
        {designType && <div className="goToInitChoiceBtn" style={{"--f-main-color":mainColor}} onClick={()=> window.history.back()}><span>Annuler</span><i className="icon material-icons">close</i></div>}
      </div>
    );
}


